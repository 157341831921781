import Vue from 'vue'

Vue.filter('unixTimeFormat', function (value, fmt) {
  if (!value) {
    return '';
  }
  if (!fmt) {
    fmt = 'yyyy-MM-dd';
  }
  let _date = new Date(value);
  var o = {
    "M+": _date.getMonth() + 1,                 //月份
    "d+": _date.getDate(),                    //日
    "h+": _date.getHours(),                   //小时
    "m+": _date.getMinutes(),                 //分
    "s+": _date.getSeconds(),                 //秒
    "q+": Math.floor((_date.getMonth() + 3) / 3), //季度
    "S": _date.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (_date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
});
Vue.filter('numberFormat', function (number, decimals = 2) {
  return (number / 1).toFixed(decimals)
});
Vue.filter('textCut', function (text, len = 100, endfix = '...') {
  return text.length > len ? text.substr(0, len - endfix.length) + endfix : text;
});
Vue.filter('htmlCut', function (html, len = 100, endfix = '...') {
  let re1 = new RegExp("<.+?>", "g");
  let msg = html.replace(re1, "");
  msg = msg.replace("&nbsp;", "");
  msg = msg.length > len ? msg.substr(0, len - endfix.length) + endfix : msg;
  return msg;
});
Vue.filter('formatDate',(_time,_tag,_show)=>{
	let date= new Date(_time*1000);
	let str = '-'
	let month = date.getMonth() + 1
	let day = date.getDate()
  let hour = date.getHours();
  let minute = date.getMinutes();
  let myDate = false
	if(_tag){
		str = _tag
	}
	if(_show){
    myDate = date.getFullYear() + str + (month > 9 ? month : '0'+month) + str +(day > 9 ? day : '0'+day)+ ' ' + hour + ':' + minute
  }else{
    myDate = date.getFullYear() + str + (month > 9 ? month : '0'+month) + str +(day > 9 ? day : '0'+day)
  }
	return myDate
})
Vue.filter('htmlImg', function (text) {
  if (!text) {
    return [];
  }
  let imgReg = /<img.*?(?:>|\/>)/gi;
  let srcReg = /src=['"]?([^'"]*)['"]?/i;
  let arr = text.match(imgReg);
  if(!arr){
    return []
  }
  let result = [];
  for (let i = 0; i < arr.length; i++) {
    let src = arr[i].match(srcReg)
    result.push(src[1])
  }
  return result;
});
Vue.filter('htmlBase64Clean', function (text) {
  if (!text) {
    return [];
  }
  let imgReg = /<img.*?(?:>|\/>)/gi;
  let srcReg = /src=['"]?([^'"]*)['"]?/i;
  let arr = text.match(imgReg);
  let result = text;
  for (let i = 0; i < arr.length; i++) {
    let src = arr[i].match(srcReg)
    if (src[1].substr(0, 10) == 'data:image') {
      result = result.replace(arr[i], '')
    }
  }
  return result;
});
Vue.filter('videoTime', function (time) {
  let seconds = (time % 60) + '';
  if (seconds.length == 1) seconds = '0' + seconds;
  let minutes = (parseInt(time / 60) % 60) + '';
  if (minutes.length == 1) minutes = '0' + minutes;
  let hours = (parseInt(time / 3600)) + '';
  if (hours.length == 1) hours = '0' + hours;
  if (hours > 0) {
    return hours + ":" + minutes + ":" + seconds;
  } else {
    return minutes + ":" + seconds;
  }
});
Vue.filter('uniqueId', function (prefix) {
  return prefix + (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
})
