import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: false,
    manager: false,
    lang: 'en',
    needAuth: true,
    appList: [],
    menu: [],
    menuCollapsed: false,
    commonHeader: {'x-from': 'web', 'x-language': 'zh'},
    num:false,
    msgTimer:false
  },
  mutations: {
    setToken(state, data) {
      state.token = data
      if (data) {
        localStorage.setItem('token', data)
        state.commonHeader['x-auth'] = data
        // store.dispatch('StartTime')
      } else {
        delete state.commonHeader['x-auth']
        store.dispatch('EndTime')
        localStorage.removeItem('token')
      }
    },
    setTimer(state, data) {
      state.msgTimer = data
    },
    setAccount(state, data) {
      state.manager = data
    },
    setNum(state, data) {
      state.num = data
    },
    setNeedAuth(state, data) {
      state.needAuth = data
    },
    setMenu(state, data) {
      state.menu = data
    },
    setSelectedMenu(state, data) {
      state.selectedMenuKey = data
    },
    setMenuCollapsed(state, data) {
      state.menuCollapsed = data
    },
    setLang(state, data) {
      state.lang = data
    },
  },
  actions: {
    StartTime({commit}){
      commit('setTimer',setInterval(()=>{
        store.dispatch('AccountMsg')
      },10000))
    },
    EndTime({commit,state}){
      clearInterval(state.msgTimer)
      commit('setTimer',false)
    },
    AccountInfo({commit, state}, toPath) {
      return new Promise((resolve, reject) => {
        if (!state.token && !localStorage.getItem('token')) {
          reject()
          return
        }
        axios.get('/api/manage/account/0?expand=join_team,own_team,menu').then(function (response) {
          commit('setAccount', response)
          response.toPath = toPath
          commit('setMenu', response.menu)
          resolve()
        }).catch(function () {
          commit('setToken', false)
          commit('setAccount', false)
          reject()
        })
      })
    },
    AccountMsg({commit}){
      return new Promise((resolve) => {
        axios.get('/api/manage/message').then(function (res) {
          let obj = {}
          let num = 0
          for (let item in res) {
            num += res[item];
          }
          obj = {...res}
          obj.num = num
          commit('setNum', obj)
          resolve()
        })
      })
    },
  }
})
export default store
