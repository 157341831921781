<template>
  <div class="avatar_app" id="avatar_app">
    <router-view ref="container"/>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'avatar_app',
    beforeMount() {
      //VUE_APP_API_TARGET = 'http://api.avatar.com/v1/'
    }
  }

</script>

<style lang="less">
  @import "./assets/style/basic";
  .ant-layout{
    background: #eff0f1 !important;
  }
  .bold{
    font-weight: bold;
  }
  .margin{
    margin-top: 20px !important;
  }
  .ant-btn-primary{
    background-color: #007aff;
    border-color:#007aff;
  }
  .ant-btn-primary:hover{
    background-color: #007aff;
  }
  div.distpicker-address-wrapper{
    display: inline-block;
  }
  div.distpicker-address-wrapper>label{
    width: 214px;
    height: 40px;

  }
  div.distpicker-address-wrapper>label:nth-child(1){
    margin-right: 13px;
  }
  div.distpicker-address-wrapper>label select{
    width: 186px;
    height: 40px;
    border: solid 1px #eeeeee;
    border-radius: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    background: url("./assets/img/icon.png")  no-repeat center right 10px transparent;
    color: #333;
    appearance: none;
    background-size: 12px 7px;
    padding-left: 10px !important;
    box-sizing: border-box;
    border-radius: 4px;
  }
  div.distpicker-address-wrapper>label select:focus,div.distpicker-address-wrapper>label select:focus-visible{
    outline-color: #ff4646;
  }
  .distpicker-address-wrapper select{
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    padding-left: 10px !important;
  }
</style>
