import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const NotFound = resolve => require(['../pages/not-found'], resolve)
const SignIn = resolve => require(['../pages/sign-in'], resolve)
const Login = resolve => require(['../pages/login'], resolve)
const SignUp = resolve => require(['../pages/sign-up'], resolve)
const Dashboard = resolve => require(['../pages/index'], resolve)
const QrCode = resolve => require(['../pages/qrcode'], resolve)
const AccountCenter = resolve => require(['../pages/account/center'], resolve)
const RoleIndex = resolve => require(['../pages/team/role'], resolve)
const TeamInfo = resolve => require(['../pages/team/info'], resolve)
const TeamJoin = resolve => require(['../pages/team/join'], resolve)
const MembersIndex = resolve => require(['../pages/team/member'], resolve)
const Agreement = resolve => require(['../pages/agreement'], resolve)
const VR = resolve => require(['../pages/content/vr'], resolve)
const WechatPortal = resolve => require(['../pages/wechat/portal/index'], resolve)
const WechatApp = resolve => require(['../pages/wechat/app'], resolve)
const WechatDetail = resolve => require(['../pages/wechat/detail'], resolve)
const WechatPortalDetail = resolve => require(['../pages/wechat/portal/detail'], resolve)
const WechatPortalUser = resolve => require(['../pages/wechat/portal/components/user'], resolve)
const WechatAdd = resolve => require(['../pages/wechat/add'], resolve)
const LauncherApp = resolve => require(['../pages/launcher/app'], resolve)
const LauncherDetail = resolve => require(['../pages/launcher/detail'], resolve)
const Software = resolve => require(['../pages/software/project'], resolve)
const SoftwareDetail = resolve => require(['../pages/software/detail'], resolve)
const Video = resolve => require(['../pages/about/video'], resolve)
const Doc = resolve => require(['../pages/about/doc'], resolve)
let path = [
  {
    path: '/',
    name: Dashboard.name,
    component: Dashboard,
    meta: {auth: true}
  },
  {
    path: '/qrcode',
    name: QrCode.name,
    component: QrCode,
    meta: {auth: true}
  },
  {
    path: '/about/video',
    name: Video.name,
    component: Video,
  },
  {
    path: '/about/:id',
    name: Doc.name,
    component: Doc,
  },
  {
    path: '/agreement',
    name: Agreement.name,
    component: Agreement,
  },
  {
    path: '/sign-in',
    name: SignIn.name,
    component: SignIn
  },
  {
    path: '/login',
    name: Login.name,
    component: Login
  },
  {
    path: '/sign-up',
    name: SignUp.name,
    component: SignUp
  },
  {
    path: '/account/center',
    name: 'center',
    component: AccountCenter,
    meta: {auth: true}
  },
  {
    path: '/team/role',
    name: RoleIndex.name,
    component: RoleIndex,
    meta: {auth: true}
  },
  {
    path: '/team/info',
    name: TeamInfo.name,
    component: TeamInfo,
    meta: {auth: true}
  },
  {
    path: '/team/member',
    name: MembersIndex.name,
    component: MembersIndex,
    meta: {auth: true}
  },
  {
    path: '/team/join',
    name: TeamJoin.name,
    component: TeamJoin,
    meta: {auth: true}
  },
  {
    path: '/content/vr',
    name: VR.name,
    component: VR,
    meta: {auth: true}
  },
  {
    path: '/wechat/portal',
    name: WechatPortal.name,
    component: WechatPortal,
    meta: {auth: true}
  },
  {
    path: '/wechat/app',
    name: WechatApp.name,
    component: WechatApp,
    meta: {auth: true}
  },
  {
    path: '/wechat/app/:id/:type(\\w+)*',
    name: WechatDetail.name,
    component: WechatDetail,
    meta: {auth: true}
  },
  {
    path: '/wechat/portal/:id/:type(\\w+)*',
    name: WechatPortalUser.name,
    component: WechatPortalDetail,
    meta: {auth: true}
  },
  {
    path: '/wechat/add',
    name: WechatAdd.name,
    component: WechatAdd,
    meta: {auth: true}
  },
  {
    path: '/launcher/cus',
    name: Software.name,
    component: Software,
    meta: {auth: true}
  },
  {
    path: '/launcher/cus/:id/:type(\\w+)*',
    name: SoftwareDetail.name,
    component: SoftwareDetail,
    meta: {auth: true}
  },
  {
    path: '/launcher/saas',
    name: LauncherApp.name,
    component: LauncherApp,
    meta: {auth: true}
  },
  {
    path: '/launcher/saas/:id/:type(\\w+)*',
    name: LauncherDetail.name,
    component: LauncherDetail,
    meta: {auth: true}
  },
  {
    path: '/*',
    name: NotFound.name,
    component: NotFound
  },
]


let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: path
})

export default router;
