import './config/release'
import './plugins'
import router from './router'
import Vue from 'vue'
import store from './store'
import App from './App.vue'
import Viser from 'viser-vue'
import Vdistpicker from 'v-distpicker'
import colorpicker from './components/color.vue'

Vue.component('colorpicker', colorpicker)
Vue.component('v-distpicker', Vdistpicker)
Vue.use(Viser)
store.commit('setToken', localStorage.getItem('token'))
router.beforeEach((to, from, next) => {
  if (!to.meta || !to.meta.auth) {
    next();
    return;
  }
  if (to.meta && to.meta.auth && !store.state.manager) {
    if (!localStorage.getItem('token')) {
      if (window.location.href.indexOf('/qrcode') != -1) {
        next('/login')
      } else {
        next('/sign-in')
      }
      return
    }
    store.dispatch('AccountInfo', to.path).then(() => {
      next()
    }).catch(() => {
      if (window.location.href.indexOf('/qrcode') != -1) {
        next('/login')
      } else {
        next('/sign-in')
      }
    })
  } else {
    next()
  }
})

new Vue({
  el: '#avatar_admin',
  router,
  store,
  render: h => h(App)
});
